.main{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: white;
    flex-direction: column;
}
.uploadContainer{
    height: auto;
    padding: 20px;
    width: 80vw;
    border-radius: 50px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
                -20px -20px 60px #ffffff;
                display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 5vh;
    position: relative;
}
.selectContainer{
    height: auto;
    padding: 20px;
    width: 80vw;
    border-radius: 50px;
    background: #ffffff;
    box-shadow:  20px 20px 60px #d9d9d9,
                -20px -20px 60px #ffffff;
    display: flex;
    align-items: center;
    padding-left: 5vw;
    flex-direction: row;
    margin: 5vh;
    position: relative;
}
.neumorphicselect {
    border: none;
    border-radius: 50px;
    background: aliceblue;
    padding: 10px;
    font-size: 16px;
    outline: none;
    width: 200px;
    appearance: none; /* Removes default dropdown arrow */
  }
  
  .neumorphicselect:hover {
    box-shadow: 8px 8px 16px #babecc, -8px -8px 16px #ffffff;
  }
  
  .neumorphicselect:active {
    box-shadow: inset 4px 4px 8px #babecc, inset -4px -4px 8px #ffffff;
  }
  
  .neumorphicselect option {
    background: #f0f2f5;
    color: #333;
    font-size: 16px;
  }
  .items{
    height: auto;
    border-radius: 50px;
    background: #ffffff;
    box-shadow:  10px 10px 30px #d9d9d9,
                -10px -10px 10px #ffffff;
  }
  .nameInput{
    width: 60vw;
    border-radius: 60px;
    height: 5vh;
    border: 0;
    padding: 10px;
    font-size: 20px;
    margin-left: 20px;
    background: paleturquoise;
    transition: background .3s ease;
    color: #000000;
  }
  .searchInput{
    width: 60vw;
    border-radius: 60px;
    height: 5vh;
    padding: 10px;
    font-size: 20px;
    margin-left: 7vw;
    background: white;
    transition: background .3s ease;
    color: #000000;
  }
  .box {
    width: 50vw;
    height: 12vh;
    border-radius: 0 0 60px 80px;
    /* border: 3px dotted #fe0222; */
    background-color: black;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progressbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: red;
    transform-origin: 0%;
  }
  